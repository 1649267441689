
const API_BASE_URL = 'https://devapi.tikkets.com/public/api/'; 
const API_BASE_URL_FOR_EXPORT = 'https://devapi.tikkets.com/public/api/organizer/';
const API_BASE_URL_FOR_EXPORT_Agent = 'https://devapi.tikkets.com/public/api/agent/';
export const API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}user/login`,
  ORGLOGIN: `${API_BASE_URL}login`,
  REGISTER: `${API_BASE_URL}user/register`,
  ORG_REGISTER: `${API_BASE_URL}register`,
  ALL_EVENT: `${API_BASE_URL}all-events`,
  BLOG:`${API_BASE_URL}all-blogs`,
  MY_TIKKET:`${API_BASE_URL}my-tickets`,
  FETCH_CHILD_TIKKET:`${API_BASE_URL}getOrder/`,
  PRINT_TIKKET:`${API_BASE_URL}order-ticket-print`,
  ALLCATEGORY_FETCH:`${API_BASE_URL}all-category`,
  EVENT_FETCH_BY_ID:`${API_BASE_URL}events-category/`,
  HOME_DATA:`${API_BASE_URL}index`,
  FaqData:`${API_BASE_URL}page/faq`,
  SEARCH_DATA:`${API_BASE_URL}event/general/search`,
  FETCH_EVENT_TIKKETS:`${API_BASE_URL}event`,
  BLOG_DETAILS:`${API_BASE_URL}blog-detail`,
  ADD_FAVRT:`${API_BASE_URL}add-favorite`,
  ORG_CHECK_LOGIN:`${API_BASE_URL}user/logged-in`,
  CHECKOUTCARDPAYMENT:`${API_BASE_URL}createOrder`,
  PROFILE_PAGE_API:`${API_BASE_URL}view-organizers-profile`,
  COUPON_CART:`${API_BASE_URL}check-code`,
  FETCH_STATE:`${API_BASE_URL}add-followList/organizer/`,
  FETCH_PROFILE_USER:`${API_BASE_URL}user/profile`,
  EDIT_PROFILE_USER:`${API_BASE_URL}user/edit-profile`,
  UserSearchFind:`${API_BASE_URL}ticket-transfer/user-search`,
  transferTicket:`${API_BASE_URL}ticket/transfer`,
  fetchBanner:`${API_BASE_URL}home/banner`,
  FORGET_PASSWORD:`${API_BASE_URL}forget-password`,
  FetchPrice :`${API_BASE_URL}index`,
  CheckeventCode :`${API_BASE_URL}check-event-code`,
  Change_password_PROFILE_USER :`${API_BASE_URL}user/change-password`,
  dummy_cart :`${API_BASE_URL}dummy-cart`,
  fetchPages :`${API_BASE_URL}pages`,
  fetchPagesSingle :`${API_BASE_URL}page`,
  checkEventCurrency :`${API_BASE_URL}event_currencies_checker?event_ids=`,
  get_Fee :`${API_BASE_URL}getcountryfee?`,
  Send_Payment :`${API_BASE_URL}paywithbsd?`,
  
};

export default API_ENDPOINTS;
